import React from 'react';
import Layout from '../components/Layout';
import Textteaser from '../components/Textteaser';
import useStaticMetadata from '../hooks/use-sitemetadata';

const IndexPage = () => {
  const { iframeSrc, iframeHeight } = useStaticMetadata();

  return (
    <Layout>
      <h1 className="flex justify-center mb-12 sr-only">
      Wie klickst du?
      </h1>

      <div className="relative px-1 lg:py-0">
        <iframe
          className="relative mx-auto max-w-sm lg:max-w-lg w-full h-screen outline-gray"
          title="Wie klickst du? Interaktive Anwendung"
          src={iframeSrc}
        ></iframe>
      </div>

      <div className="flex justify-center lg:px-12 px-6 lg:mt-24 my-12">
        <img src="/images/wkd-logo.svg" alt="Wie klickst du? Logo" width="200" height="132" />
      </div>

      <Textteaser
        title="#JEDERBEITRAGZÄHLT"
        text="Der Klimawandel betrifft uns alle. Mit dem Klimaschutzgesetz hat sich Deutschland das Ziel gesetzt, die Treibhausgasemissionen weiter zu reduzieren. Dazu kann jeder und jede etwas beitragen. Deshalb unterstützt die Bundesregierung auch gezielt Menschen, die klimafreundlich handeln. Was du tun kannst und was die Bundesregierung für den Klimaschutz tut – dazu mehr auf unserer Themenseite."
        link="https://www.bundesregierung.de/breg-de/themen/klimaschutz"
        linkText="www.unser-klimaschutz.de"
      />
    </Layout>
  );
};

export default IndexPage;
