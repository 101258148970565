import React from 'react';

const Textteaser = ({ title, text, link, linkText }) => {
  return (
    <section
      className="lg:px-12 px-6 lg:mt-24 my-12 text-center"
      aria-labelledby="teaser-heading"
    >
      <div className="mx-auto max-w-sm lg:max-w-lg w-full">
        <header>
          <h2
            id="teaser-heading"
            className="text-2xl lg:text-3xl leading-tight font-bold"
          >
            {title}
          </h2>
        </header>
        <p className="mt-6 mb-6 lg:text-xl font-medium">{text}</p>
        <a
          className="lg:text-xl font-bold uppercase underline"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
          <span className="sr-only">(öffnet neues Fenster)</span>
        </a>
      </div>
    </section>
  );
};
export default Textteaser;
